/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hi 👋.",
  title2: "Hadi Arshad",
  logo_name: "hadi.a()",
  nickname: "hadi / hArj",
  full_name: "Abdul Hadi Arshad",
  // "Full Stack Developer, Open Source Enthusiast 🔥. Always learning."
  subTitle:
    "Full Stack Developer, Open Source Enthusiast. Always learning.",
  // resumeLink:
  //   "https://docs.google.com/document/d/1A_jWRG74Rst427tg1izLa6vRXclS9_9F856jWJPJlDY/edit?usp=sharing",
    resumeLink:
    "https://drive.google.com/drive/folders/1NQ6Vh6Kw-cclzeoNIueo4Hyh8mfV9fKH?usp=sharing",
  mail: "mailto:hadiarshad970133@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/hadiarshad6767",
  linkedin: "https://www.linkedin.com/in/hadi-arshad-8b3a81239/",
  gmail: "hadiarshad970133@gmail.com",
  gitlab: "https://gitlab.com/hadiansari6767",
  facebook: "https://www.facebook.com/hadi.ansari.509",
  twitter: "https://twitter.com/hadi_970",
  instagram: "https://www.instagram.com/hadiansari_970/",
  snapchat: "https://www.snapchat.com/add/hadiansari6767?share_id=MzBFRTgyRTUtREIzRS00QzQ5LUE4ODktNTY3RkFDOUE3N0FD&locale=en_PK",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive website front end",
        "⚡ Developing mobile applications using Flutter",
        "⚡ Creating application backend in Spring, Node & Express",
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "AngularJS",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#dd1b16",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "IOS",
          fontAwesomeClassname: "logos:apple-app-store",
          // style: {
          //   color: "#1a1c1a",
          // },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#01579b",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        // {
        //   skillName: "Netlify",
        //   fontAwesomeClassname: "simple-icons:netlify",
        //   style: {
        //     color: "#38AFBB",
        //   },
        // },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        // {
        //   skillName: "GitHub Actions",
        //   fontAwesomeClassname: "simple-icons:githubactions",
        //   style: {
        //     color: "#5b77ef",
        //   },
        // },
      ],
    },
  ],
};




const social = {
  data: [
    {
    
      social: [
        {
          skillName: "Twitter",
          fontAwesomeClassname: "devicon:twitter",
          style: {
            color: "#E34F26",
          },
        },
      ]
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University Of Gujrat",
      subtitle: "Bachelor Of Sciences in Computer Science",
      logo_path: "uog.png",
      alt_name: "UOG",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I have studied core subjects like Programming Fundamentals,Object Oriented Programming,Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed courses for Backend , Web , Mobile App Development",
        "⚡ I have done specialization in Modeling and Simulation",
        "⚡ I have implemented several projects based on what I've learnt under my graduation ",
        "⚡ I have score above 70% Marks",
      ],
      website_link: "https://uog.edu.pk/main.php",
    },
    {
      title: "Punjab Group of Colleges",
      subtitle: "Pre-Engineering",
      logo_path: "pgc.png",
      alt_name: "pgc",
      duration: "2016 - 2018",
      descriptions: [
        "⚡ I have studied sciences subjects like Maths, Physics, Chemistry",
        "⚡ I have studied arts subjects like English, Urdu, Islamyat, Pakistan studies",
        "⚡ I have score 79% Marks",

        // "⚡ I'm currently pursuing my bachelors in Information Technology."
        //  "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://pgc.edu/",
    },
    {
      title: "Dar-e-Arqam Schools",
      subtitle: "Matriculation",
      logo_path: "dar.jpg",
      alt_name: "SSGC",
      duration: "2014 - 2016",
      descriptions: [
        "⚡ I have studied subjects like English, Urdu, Maths, Computer, Physics, Chemistry, etc.",
        "⚡ I was class representer ",
        "⚡ I took participate in games at school level",
        "⚡ I have score 85% Marks",
      ],
      website_link: "https://das.edu.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Spring Bootcam with microservices",
      subtitle: "Spring & Hibernate",
      logo_path: "spring.png",
      certificate_link:
        "https://www.udemy.com/course/spring-boot-restful-web-services-tutorial-free-course/learn/lecture/29680280?start=0#overview",
      alt_name: "Java",
      // color_code: "#2AAFED",
      color_code: "#F3F3F3",
    },
    {
      title: "Flutter Development",
      subtitle: "Flutter & Dart",
      logo_path: "flutter-1.png",
      certificate_link:
        "https://www.udemy.com/course/learn-flutter-dart-to-build-ios-android-apps/learn/lecture/10459802?start=0#overview",
      alt_name: "Flutter",
      // color_code: "#f36c3d",
      color_code: "#F3F3F3",
    },
    {
      title: "MEAN Development",
      subtitle: "Angular & Nodejs",
      logo_path: "angular-1.svg",
      certificate_link:
        "https://www.udemy.com/course/mern-stack/",
      alt_name: "Angular & NodeJs",
      // color_code: "#2AAFED",
      color_code: "#F3F3F3",
    },

    // {
    //   title: "Postman Student Expert",
    //   subtitle: "Postman",
    //   logo_path: "postman.png",
    //   certificate_link:
    //     "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
    //   alt_name: "Postman",
    //   // color_code: "#f36c3d",
    //   color_code: "#fffbf3",
    // },
    // {
    //   title: "Hackathon",
    //   subtitle: "Hack The Mountains",
    //   logo_path: "hackathon1.svg",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
    //   alt_name: "hackathon",
    //   color_code: "#E2405F",
    // },
    // {
    //   title: "A300: Atlas Security",
    //   subtitle: "MongoDB University",
    //   logo_path: "mongo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
    //   alt_name: "MongoDB University",
    //   // color_code: "#F6B808",
    //   color_code: "#47A048",
    // },
    // {
    //   title: "Cyber Security & Cyber Forensics",
    //   subtitle: "Workshop at IIT Bombay",
    //   logo_path: "iit.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
    //   alt_name: "Workshop",
    //   color_code: "#2AAFED",
    // },
    // {
    //   title: "MLH Local Hack Day: Build",
    //   subtitle: "Major League Hacking",
    //   logo_path: "mlh-logo.svg",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
    //   alt_name: "Google",
    //   color_code: "#fe0037",
    // },
    // {
    //   title: "Hack20",
    //   subtitle: "Flutter International Hackathon",
    //   logo_path: "flutter.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
    //   alt_name: "Flutter International Hackathon",
    //   color_code: "#2AAFED",
    // },
   
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've done some projects on my own and some with my team. I am actively looking for projects to explore more.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Cwiztech",
          company_url: "https://www.cwiztech.com/",
          logo_path: "cwz.png",
          duration: "Nov 2021 - Present",
          location: "Office",
          description:
          `Train, manage and provide guidance to junior software development staff.
          Work closely with the Project Manager and Team Leads on change request functions.
          Develops new and maintains existing applications.
          Increased productivity and problem-solving technics by 20%.
          My work is doing development through flutter. 80% of my work was doing front development and the remaining 20% was DevOps-related tasks.
          `,
            // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Full Stack Developer (Intern)",
          company: "Essentialsofts",
          company_url: " ",
          logo_path: "valora.jpg",
          duration: "Mar 2020 - Mar 2021",
          location: "Office",
          description:
          `
          I primarily use MEAN. 50% of my work was doing front development and the remaining 50% was backend-related tasks.
          `,
            // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        {
          title: "Full Stack Developer (Freelancer)",
          company: "UpWork",
          company_url: "https://www.upwork.com/freelancers/~015c8bf583b636466d",
          logo_path: "upwork-1.svg",
          duration: "Mar 2020 - Presnet",
          location: "Work From Home",
          description:
          `Work closely with the Client and Team on change request functions.
          Build hybrids and natives applications on demand of Clients
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#ee3c26",
        }
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
                {
          title: "GitHub",
          company: "GitHub",
          company_url: "https://github.com/hadiarshad6767",
          logo_path: "github.png",
          duration: "Nov 2021- Present",
          location: "Work from Home",
          description:
          "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
        // {
        //   title: "",
        //   company: "CWOC",
        //   company_url: "https://crosswoc.ieeedtu.in/",
        //   logo_path: "cwoc.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
        //   color: "#4285F4",
        // },
        // {
        //   title: "Campus Hustler",
        //   company: "Skillenza",
        //   company_url: "https://skillenza.com/",
        //   logo_path: "skillenza.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work from Home",
        //   description: "Spread Awareness of new Technologies and new Opportunities to Students and Grow Skillenza Community.",
        //   color: "#196acf",
        // },
        // {
        //   title: "GitHub Student Developer",
        //   company: "GitHub",
        //   company_url: "https://github.com/",
        //   logo_path: "github.png",
        //   duration: "Nov 2019 - Present",
        //   location: "Work from Home",
        //   description:
        //   "Contribute to Open Source Community and Open Source Project.",
        //   color: "#040f26",
        // },
        // {
        //   title: "Google Local Guide",
        //   company: "Google Map",
        //   company_url: "https://maps.google.com/localguides/",
        //   logo_path: "localguide.png",
        //   duration: "Sep 2018 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "GDG Student Volunteer",
        //   company: "Google Developer Groups",
        //   company_url: "https://gdg.community.dev/",
        //   logo_path: "gdg.png",
        //   duration: "Feb 2021 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Google Developer Group Surat Student Volunteer and Member.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "E. F. I. Student Volunteer",
        //   company: "ENVIRONMENTALIST FOUNDATION OF INDIA",
        //   company_url: "https://indiaenvironment.org/",
        //   logo_path: "efi.png",
        //   duration: "Apr 2017 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Take time out over weekends to join a gang of nation builders, nature enthusiasts to conserve what is left.",
        //   color: "#5a900f",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Flutter Projects, Spring Backend Projects, and Angular Project. Below are some of my projects.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "hadi.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/hadi_970",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [

    {
      id: "0",
      name: "MyChamp",
      url: "https://mychamp.app/",
      description:
        "This is Tournment App. The user can login as Admin on web application. Players and Hosts can login on mobile application. Tournaments & Players can be added by host.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      id: "1",
      name: "RoutePlanner",
      url: "http://sales.kbfoods.com/",
      description:
        "A Complete Route Management system develop for logistic companies. It also contain mobile application as a service use by drivers. It provide easiness to deliver products",
      languages: [
        {
          name: "Angular",
          iconifyClass: "vscode-icons:file-type-angular",
        },
        // {
        //   name: "Spring",
        //   iconifyClass: "vscode-icons:file-type-spring"
        // },
        {
          name: "Spring",
          // iconifyClass: "logos-spring",
          iconifyClass: "logos:spring-icon",
        },
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    {
      id: "2",
      name: "KairaGroups",
      url: "http://qamarkaira.pk/",
      description:
        "A Personal Portfolio of Politician embedded with mobile application. It is use to handle social media on single click. Mobile application contain more advance features",
      languages: [

        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Spring",
          // iconifyClass: "logos-spring",
          iconifyClass: "logos:spring-icon",
        },
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
      ],
    },
    
    {
      id: "3",
      name: "Bike Managment System",
      url: "https://github.com/hadiarshad6767",
      description:
        "A Data Structures Consloe Project use to manage records for bike showroom.",
      languages: [
        {
          name: "C",
          iconifyClass: "vscode-icons:file-type-c",
        },
 
      ],
    },
    {
      id: "4",
      name: "GroceryApp",
      url: " ",
      description:
        "A hybrid appilcation through which user can buy online products. Admin can manage products on web app. User have to login to use application. ",
      languages: [
        {
          name: "Spring",
          // iconifyClass: "logos-spring",
          iconifyClass: "logos:spring-icon",
        },
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    
    {
      id: "5",
      name: "Crash Simulation",
      url: "https://github.com/hadiarshad6767",
      description:
        "The Automated Crash Test Simulation system simulates a vehicle crash scenario, where the vehicle hits to an obstacle, causing damage to the vehicle and possible passenger casualties.",
      languages: [
        {
          name: "C",
          iconifyClass: "vscode-icons:file-type-c",
        },
      ],
    },
    {
      id: "6",
      name: "Beachfront Hotel Managment",
      url: "https://github.com/hadiarshad6767",
      description:
        "A Management System which suggest rooms for user on basis in inputs",
      languages: [
        {
          name: "Python",
          iconifyClass: "vscode-icons:file-type-python",
        },
      ],
    },

    ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
